import React from 'react';
// Structure imports
import Layout from '@components/structure/Layout';
// Utility imports
import Seo from '@components/utility/Seo';
import CtaButton from '@mui-components/ctaButton';
import { Grid } from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

const RegConfirmationIhBranded = () => (
	<Layout pageType='ih-branded' className='idiopathicHypersomnia no-tabs' pixelCode='Complete Registration'>
		<Seo title='Confirmation of Webinar Registration  | Jazz Webinars' description='' />
		<div>
			<StaticImage
				className='test-desktop'
				src='../../../static/images/ih-branded-hero.png'
				alt='Idiopathic hypersomnia Hero Banner Desktop on confirmation page'
			/>
			<div className='ih-branded-hero-mobile'></div>
			<section className='container firstSection'>
				<Grid container>
					<Grid item xs={12}>
						<span className='back-link'>
							&#8249; <a href='/xywav-idiopathic-hypersomnia'>Back to Webinar Library</a>
						</span>
						<div className='lv-copy v-center tabletPosition'>
							<h1>
								Learn about XYWAV from <br class='mobileOnly' />
								experts in sleep medicine <br class='mobileOnly' />
							</h1>
						</div>
					</Grid>
				</Grid>
			</section>
		</div>
		<section className='smaller-container center'>
			<h2>Thanks for signing up!</h2>
			<div className='gradient-divider center'>
				<img alt='' src='/images/gradient-divider-ih.svg' />
			</div>
			<p className='center'>
				We’re looking forward to having you in our live webinar session(s). A
				confirmation email will be sent to your inbox with the details of the
				webinar(s) you've signed up for.
			</p>
			<p>
				<strong>
					In the meantime, why not browse through our Webinars On-Demand video
					library?
				</strong>
			</p>

			<Link
				to={`/xywav-idiopathic-hypersomnia`}
				state={{ onDemandTabSelected: true }}
				className='cta watch-cta rounded-link'>
				Watch Webinars On Demand
			</Link>
		</section>
	</Layout>
);

export default RegConfirmationIhBranded;
